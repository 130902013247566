import React from 'react';
import leafLogo from '../assets/leafLogo.jpg';
import './Footer.css';

export default function Footer() {
  return (
    <span className='footer-span'>
      <img
        className='footer-logo'
        src={leafLogo}
        alt='leaf logo'
      />
      <p>website by Heather &copy;2023</p>
    </span>
  )
}
