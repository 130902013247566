import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

export default function Menu() {
  return (
    <>
      <input id='menu__toggle' type='checkbox' />
      <label class='menu__btn' for='menu__toggle'>
        <span></span>
      </label>

      <ul class='menu__box'>
        <li>
          <Link
            to='/'
            class='menu__item'
          >
            Home
          </Link>
        </li>
        {/* <li>
          <Link
            to='/event'
            class='menu__item'
          >
            Event
          </Link>
        </li> */}
        {/* <li>
          <Link
            to='/accommodations'
            class='menu__item'
          >
            Accommodations
          </Link>
        </li> */}
        <li>
          <Link
            to='/photos'
            class='menu__item'
          >
            Photos
          </Link>
        </li>
        <li>
          <Link
            to='/registry'
            class='menu__item'
          >
            Registry
          </Link>
        </li>
      </ul>
    </>
  )
}
