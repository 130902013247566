import React, { useEffect } from 'react';
import Footer from '../footer/Footer';
import 'aos/dist/aos.css';
import AOS from 'aos';
import leafAndFeatherBanner2 from '../assets/leafAndFeatherBanner2.jpg';
import './Landing.css';

export default function Landing() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div
      data-aos='fade-zoom-in'
      data-aos-offset='200'
      data-aos-easing='ease-in-sine'
      data-aos-duration='900'
    >
      <main className='hero-banner'>
        <h1 className='hero-text'>
          Heather & Noah
        </h1>
      </main>
      <section className='subHeading'>
        <img
          className='leafAndFeatherBanner'
          src={leafAndFeatherBanner2}
          alt='leaves and feathers'
        />
        <p className='subtitle'>
          Are married!
        </p>
        {/* <a className='details-p'
          href='https://docs.google.com/forms/d/e/1FAIpQLSeMYrDMOQ8k1aBTASqU-G263YFa6HwNao45wo0cXVEp_uxLjA/viewform?usp=sf_link'
          target='_blank'
          rel='noreferrer'
          style={{ color: 'var(--gold)' }}
        >
          <p style={{ color: 'var(--gold)' }}>
            RSVP Here
          </p>
        </a> */}
        <div className='landingPageLinks'>
          <a
            href="https://www.youtube.com/watch?v=p-RK06Ma9eE"
            target='_blank'
            rel='noreferrer'
            style={{ color: 'var(--gold)' }}
          >
            <p>
              View our wedding highlight VIDEO!
            </p>
          </a>
          <br />
          <a
            href="https://www.youtube.com/watch?v=CprKC2Q1UWw"
            target='_blank'
            rel='noreferrer'
            style={{ color: 'var(--gold)' }}
          >
            <p>
              <strong>
                View our full wedding VIDEO!
              </strong>
            </p>
          </a>
          <br />
          <a
            href="/photos"
            style={{ color: 'var(--gold)' }}
          >
            <p>
              View our wedding PHOTOS!
            </p>
          </a>
        </div>
        <p className='greeting'>
          {/* <em>we look forward to sharing more adventures with you</em> */}
          {/* <em>stay tuned for more updates!</em> */}
          <Footer />
        </p>
      </section>
    </div >
  )
}
