import React, { useEffect, useState } from 'react';
import { BiUpArrow } from 'react-icons/bi';
import 'aos/dist/aos.css';
import AOS from 'aos';
import leafBanner from '../assets/leafBanner.jpg';
import leafBanner2 from '../assets/leafBanner2.jpg';
import './Accommodations.css';

export default function Accommodations() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <main
      className='details-main'
      data-aos='fade-zoom-in'
      data-aos-offset='200'
      data-aos-easing='ease-in-sine'
      data-aos-duration='900'
    >
      <section className='accommodations-section'>
        <h2>
          Accommodation Options:
        </h2>
        <p className='details-p' style={{ color: 'var(--gold)' }}>
          Please book by April 30, 2023
        </p>
        <p className='details-p'>
          Rooms are available at Lithia Springs Resort

          & other locations.
        </p>
        <em className='greeting'>
          if booking at Lithia, please specify you are with the
          <br />
          Peterson-Wild Wedding Party
        </em>
      </section>
      <div className='ext-link-section'>
        <a
          href='https://lithiaspringsresort.com/bungalows-rooms-suites/'
          target='_blank'
          rel='noreferrer'
        >
          <BiUpArrow size={30} />
          <p>
            Lithia Springs Resort
          </p>
        </a>
        <a
          href='https://www.reseze.net/servlet/WebresResDesk?hotelid=1513'
          target='_blank'
          rel='noreferrer'
        >
          <BiUpArrow size={30} />
          <p>
            Ashland Springs Hotel
          </p>
        </a>
        <a
          href='https://peerlesshotel.com/rooms/'
          target='_blank'
          rel='noreferrer'
        >
          <BiUpArrow size={30} />
          <p>
            The Peerless Hotel
          </p>
        </a>
      </div>
      <img
        className='left-leaf-banner'
        src={leafBanner}
        alt='branches' />
      <img
        className='right-leaf-banner'
        src={leafBanner2}
        alt='branches' />
    </main>
  )
}
