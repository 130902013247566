import React, { useEffect } from 'react';
import { BiUpArrow } from 'react-icons/bi';
import 'aos/dist/aos.css';
import AOS from 'aos';
import leaf9 from '../assets/leaf9.jpg';
import leaf10 from '../assets/leaf10.jpg';
import './Details.css';

export default function Details() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <main
      className='details-main'
      data-aos='fade-zoom-in'
      data-aos-offset='200'
      data-aos-easing='ease-in-sine'
      data-aos-duration='900'
    >
      <img
        className='upper-leaf'
        src={leaf9}
        alt='branch' />
      <section className='details-section' id='top'>
        <h2>
          Date & Time:
        </h2>
        <p className='details-p'>
          June 3, 2023
          <br />
          Ceremony @ 3:00pm
          <br />
          <em className='greeting' style={{ color: 'var(--gold)' }}>
            please arrive by 2:30pm
          </em>
          <em className='greeting'>
            drinks, dinner, & dancing to follow
          </em>
        </p>
      </section>
      <section className='details-section'>
        <h2>
          Venue:
        </h2>
        <p className='details-p'>
          Lithia Springs Resort
          <br />
          2165 W Jackson Rd,
          <br />
          Ashland, OR 97520
        </p>
        <a
          href="https://www.google.com/maps/place/Lithia+Springs+Resort+-+Oregon's+Mineral+Waters+Retreat/@42.2199365,-122.7445364,17z/data=!4m8!3m7!1s0x54cf737c000dc54b:0x504a89aad8af6209!5m2!4m1!1i2!8m2!3d42.2200396!4d-122.7423192"
          target='_blank'
          rel='noreferrer'
        >
          <BiUpArrow size={30}
          />
          <p>
            Google Maps
          </p>
        </a>
      </section>
      <img
        className='lower-leaf'
        src={leaf10}
        alt='branch' />
    </main >
  )
}
