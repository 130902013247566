import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../menu/Menu';
import './Nav.css';

export default function Nav() {
  return (
    <>
      <nav>
        <Link
          to='/'
          class='link'
        >
          Home
        </Link>
        {/* <Link
          to='/event'
          class='link'
        >
          Event
        </Link> */}
        {/* <Link
          to='/accommodations'
          class='link'
        >
          Accommodations
        </Link> */}
        <Link
          to='/photos'
          class='link'
        >
          Photos
        </Link>
        <Link
          to='/registry'
          class='link'
        >
          Registry
        </Link>
        <span class='burgerMenu'>
          <Menu />
        </span>
      </nav>
    </>
  )
}
