import React, { useEffect } from 'react';
import { BiUpArrow } from 'react-icons/bi';
import Footer from '../footer/Footer';
import 'aos/dist/aos.css';
import AOS from 'aos';
import leafBanner from '../assets/leafBanner.jpg';
import leafBanner2 from '../assets/leafBanner2.jpg';
import './Gift.css';

export default function Gift() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <main
      className='details-main'
      data-aos='fade-zoom-in'
      data-aos-offset='200'
      data-aos-easing='ease-in-sine'
      data-aos-duration='900'
    >
      <section className='details-section' id='top'>
        <h2>
          Wedding Registry
        </h2>
        <p className='details-p'>
          Your presence at our
          <br />
          <em className='greeting' style={{ color: 'var(--gold)', fontSize: '1.4rem' }}>
            wedding celebration
          </em>
          is the gift we will cherish most.
          <br />
          <br />
          If you wish to make a traditional wedding gift,
          <br />
          please donate to our
        </p>
        <br />
      </section>
      <section className='details-section'>
        <a
          href="https://withjoy.com/heatherandnoah-2023/registry"
          target='_blank'
          rel='noreferrer'
        >
          <BiUpArrow size={30}
          />
          <p className='greeting' style={{ color: 'var(--gold)', fontSize: '1.4rem', fontFamily: "'Montserrat', sans-serif" }}>
            Honeymoon Fund!
          </p>
        </a>
      </section>
      <img
        className='left-leaf-banner-gift'
        src={leafBanner}
        alt='branches' />
      <img
        className='right-leaf-banner-gift'
        src={leafBanner2}
        alt='branches' />
    </main >
  )
}
