import React, { useEffect } from 'react';
import Footer from '../footer/Footer';
import 'aos/dist/aos.css';
import AOS from 'aos';
import hAndNHug_web from '../assets/hAndNHug_web.jpg';
import hAndNLook_web from '../assets/hAndNLook_web.jpg';
import familyPortrait_web from '../assets/familyPortrait_web.jpg';
import ellieTree_web from '../assets/ellieTree_web.jpg';
import neneTree_web from '../assets/neneTree_web.jpg';
import cakeKiss from '../assets/cakeKiss.jpg';
import coupleSitting from '../assets/coupleSitting.jpg';
import nEDance from '../assets/nEDance.jpg';
import eWalkingIn from '../assets/eWalkingIn.jpg';
import familyShot from '../assets/familyShot.jpg';
import firstDance from '../assets/firstDance.jpg';
import nByPond from '../assets/nByPond.jpg';
import nNHold from '../assets/nNHold.jpg';
import parasolLaugh from '../assets/parasolLaugh.jpg';
import salsaKiss from '../assets/salsaKiss.jpg';
import './Photos.css';

export default function Photos() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const photos = [
    {
      image: hAndNLook_web,
      title: 'Loving Look'
    },
    {
      image: neneTree_web,
      title: 'Naomi in Tree'
    },
    {
      image: familyPortrait_web,
      title: 'Family Engagement Portrait'
    },
    {
      image: ellieTree_web,
      title: 'Ellie in Tree'
    },
    {
      image: hAndNHug_web,
      title: 'Heather and Noah Hug'
    },
    {
      image: nByPond,
      title: 'Naomi by the Pond with her Parasol'
    },
    {
      image: eWalkingIn,
      title: 'Ellie Walking Down the Aisle'
    },
    {
      image: familyShot,
      title: 'Family Wedding Portrait'
    },
    {
      image: parasolLaugh,
      title: 'Heather and Noah Under the Parasol'
    },
    {
      image: coupleSitting,
      title: 'Heather and Noah Under the Gazebo'
    },
    {
      image: firstDance,
      title: 'First Dance'
    },
    {
      image: salsaKiss,
      title: 'Salsa Dance Kiss'
    },
    {
      image: nNHold,
      title: 'Noah and Naomi Hug'
    },
    {
      image: nEDance,
      title: 'Noah and Ellie dancing'
    },
    {
      image: cakeKiss,
      title: 'Cake Kiss'
    },
  ];

  const displayPhotos = photos.map(
    (photo, index) =>
      <>
        <img
          className='photo-image'
          key={photo.image + index}
          src={photo.image}
          alt='{`${photo.title}`}'
        />
      </>
  )

  return (
    <main
      className='photo-main'
      data-aos='fade-zoom-in'
      data-aos-offset='200'
      data-aos-easing='ease-in-sine'
      data-aos-duration='900'
    >
      {displayPhotos}
      <Footer />
    </main>
  )
}
