import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Nav from '../nav/Nav';
import Landing from '../landing/Landing';
import Details from '../details/Details';
import Accommodations from '../accommodations/Accommodations';
import Photos from '../photos/Photos';
import Gift from '../gift/Gift';
import './App.css';

export default function App() {
  return (
    <div>
      <Router>
        <Nav />
        <Routes>
          <Route exact path='/' element={<Landing />} />
          <Route exact path='/event' element={<Details />} />
          <Route exact path='/accommodations' element={<Accommodations />} />
          <Route exact path='/photos' element={<Photos />} />
          <Route exact path='/registry' element={<Gift />} />
        </Routes>
      </Router>
    </div>
  );
}
